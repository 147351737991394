
import { defineComponent, ref, onMounted, createVNode } from "vue";
import { notification, Modal } from "ant-design-vue";
import router from "../router";
import candidateService from "../services/candidate";
import commonService from "../services/common";
import {
  DeleteOutlined,
  SendOutlined,
  PhoneOutlined,
  BellOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  UserAddOutlined,
  CheckCircleFilled,
  UserOutlined,
  DownloadOutlined,
  ClockCircleFilled
} from "@ant-design/icons-vue";

import addNewCandidateModal from "@/components/addNewCandidate.vue";
import addNewRefereeModal from "@/components/addNewReferee.vue";
import candidateTimeLine from "@/components/candidateTimeLine.vue";

export default defineComponent({
  props: ["CandidateId"],
  emits: ["getCandidates", "updateCreditPoint"],
  watch: {
    // deep: true,
    // immediate: true,
    CandidateId(val, oldVal) {
      if (val) {
        this.getCandidateDetails(val);
      }
      if (val === undefined) {
        this.showCandidatePageFun();
      }
    },
  },
  components: {
    addNewCandidateModal,
    addNewRefereeModal,
    candidateTimeLine,
    DeleteOutlined,
    SendOutlined,
    PhoneOutlined,
    BellOutlined,
    PlusOutlined,
    UserAddOutlined,
    CheckCircleFilled,
    UserOutlined,
    DownloadOutlined,
    ClockCircleFilled
  },
  setup(props, { emit }) {
    const dashboardLoading = ref<boolean>(false);
    const candidateDetails = ref();
    const candidateRecentUpdate = ref();
    const candidateReferencetabKey = ref("1");
    const activeCandidateId = ref();
    const creatCandidateVisible = ref<boolean>(false);
    const creatRefereeVisible = ref<boolean>(false);
    const deleteRefereLoading = ref<boolean>(false);
    const candidateRemainderLoading = ref<boolean>(false);
    const refereRemainderLoading = ref<boolean>(false);
    const creaditVisible = ref<boolean>(false);
    const addNewRefereeTitle = ref();
    const classApplied = ref<boolean>(false);
    const showCandidatePage = ref<boolean>(false);
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };

    const filterOption = (input: string, option: any) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const getCandidateDetails = async (id: any) => {
      dashboardLoading.value = true;
      activeCandidateId.value = id;
      candidateReferencetabKey.value = "1";
      try {
        const res = await candidateService.getCandidateDetails(id);
        if (res.data.data.success) {
          candidateDetails.value = res.data.data.candidate;
          if (res.data.data.recent_update) {
            candidateRecentUpdate.value = res.data.data.recent_update;
          }
          showCandidatePage.value = true;
        }
        dashboardLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          dashboardLoading.value = false;
          showCandidatePage.value = false;
        }
      }
    };
    const candidatePdf = async (candidateId: any) => {
      dashboardLoading.value = true;
      try {
        const res = await candidateService.getCandiateDetailsPDF(candidateId);
        if (res.data.data.success) {
          window.open(res.data.data.pdf_url);
        }
        dashboardLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          dashboardLoading.value = false;
        }
      }
    };
    const showDeleteConfirm = async (candidateId: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this record? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deleteCandidate(candidateId);
        },
      });
    };
    const deleteCandidate = async (id: any) => {
      try {
        const res = await candidateService.deleteCandidate(id);
        if (res.data.data.success) {
          emit("getCandidates");
          notify("Success", res.data.data.message, "success");
          if (res.data.data.candidate_count === 0) {
            candidateDetails.value = undefined;
          }
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.message, "error");
        }
      }
    };
    const addNewCandidate = async () => {
      classApplied.value = !classApplied.value;
      const avilableCredit = localStorage.getItem("available_credits");
      if (avilableCredit == "0") {
        creaditVisible.value = true;
      } else {
        creatCandidateVisible.value = true;
      }
    };
    const cancleCreditVisible = async () => {
      creaditVisible.value = false;
    };
    const purchaseCredit = async () => {
      router.push(`/billing`);
    };
    const closeCandidateModal = async (data: any) => {
      if (data === "CloseModal") {
        creatCandidateVisible.value = false;
      } else {
        creatCandidateVisible.value = false;
        emit("getCandidates");
        emit("updateCreditPoint");
      }
    };
    const getAllCandidate = async () => {
      emit("getCandidates");
      emit("updateCreditPoint");
    };
    const addNewReferee = async (value: any) => {
      if (value === "first") {
        addNewRefereeTitle.value = "Please Add the Details for Referee 1";
      } else {
        addNewRefereeTitle.value = "Please Add the Details for Referee";
      }

      creatRefereeVisible.value = true;
    };
    const closeRefereeModal = async (data: any) => {
      if (data === "CloseModal") {
        creatRefereeVisible.value = false;
      } else {
        creatRefereeVisible.value = false;
        getCandidateDetails(activeCandidateId.value);
        const cId = activeCandidateId.value;
        activeCandidateId.value = null;
        setTimeout(() => {
          activeCandidateId.value = cId;
        }, 500);
      }
    };
    const sendRefereeRemindar = async (data: any) => {
      refereRemainderLoading.value = true;
      try {
        const res = await candidateService.refereeRemainder(data.id);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          getCandidateDetails(activeCandidateId.value);
        }
        refereRemainderLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
          refereRemainderLoading.value = false;
        }
      }
    };
    const showDeleteRefereeConfirm = async (data: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this referee? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deleteReferee(data);
        },
      });
    };
    const deleteReferee = async (data: any) => {
      deleteRefereLoading.value = true;
      try {
        const res = await candidateService.deleteReferee(data.id);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          getCandidateDetails(activeCandidateId.value);
          const cId = activeCandidateId.value;
          activeCandidateId.value = null;
          setTimeout(() => {
            activeCandidateId.value = cId;
          }, 500);
        }
        deleteRefereLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.message, "error");
          deleteRefereLoading.value = false;
        }
      }
    };
    const downloadReferee = async (data: any) => {
      event.stopPropagation();
      dashboardLoading.value = true;
      try {
        const res = await candidateService.getRefereeDetailsPDF(data.id);
        if (res.data.data.success) {
          window.open(res.data.data.pdf_url);
        }
        dashboardLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          dashboardLoading.value = false;
        }
      }
    };
    const showAddNewCandidate = async () => {
      classApplied.value = !classApplied.value;
    };
    const sendCandidateRemindar = async (data: any) => {
      candidateRemainderLoading.value = true;
      try {
        const res = await candidateService.sendCandidateRemainder(data.id);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
        }
        candidateRemainderLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.message, "error");
          candidateRemainderLoading.value = false;
        }
      }
    };
    const refereeDetailView = async (data: any) => {
      if (data.status === "completed") {
        localStorage.activeCandidateId = activeCandidateId.value;
        router.push(`/referee_details/${data.id}`);
      }
    };
    const showCandidatePageFun = async () => {
      showCandidatePage.value = true;
    };
    onMounted(() => {
      dashboardLoading.value = true;
      emit("getCandidates");
      showCandidatePage.value = false;
      setTimeout(() => {
        dashboardLoading.value = false;
      }, 500);
    });
    return {
      dashboardLoading,
      filterOption,
      notify,
      getCandidateDetails,
      candidateDetails,
      candidateReferencetabKey,
      showDeleteConfirm,
      addNewCandidate,
      creatCandidateVisible,
      closeCandidateModal,
      getAllCandidate,
      candidateRecentUpdate,
      activeCandidateId,
      addNewReferee,
      creatRefereeVisible,
      addNewRefereeTitle,
      closeRefereeModal,
      sendRefereeRemindar,
      deleteReferee,
      downloadReferee,
      deleteRefereLoading,
      classApplied,
      showAddNewCandidate,
      candidateRemainderLoading,
      sendCandidateRemindar,
      refereRemainderLoading,
      refereeDetailView,
      commonService,
      showDeleteRefereeConfirm,
      creaditVisible,
      cancleCreditVisible,
      purchaseCredit,
      showCandidatePage,
      showCandidatePageFun,
      candidatePdf,
    };
  },
});
