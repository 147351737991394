
import { defineComponent, ref, onMounted } from "vue";

import candidateService from "../services/candidate";
import { CalendarOutlined, UserAddOutlined, CheckCircleFilled, ClockCircleFilled, DeleteFilled } from "@ant-design/icons-vue";

export default defineComponent({
  props: ["candidateId"],
  components: {
    CalendarOutlined,
    UserAddOutlined,
    CheckCircleFilled,
    ClockCircleFilled,
    DeleteFilled
  },
    watch: {
    // deep: true,
    // immediate: true,
    candidateId(val, oldVal) {
      if (val) {
        this.getCandidateTimelines(val);
      }
    },
  },
  setup(props, { emit }) {
    const candidateTimelines = ref([]);
    const getCandidateTimelines = async (id: any) => {
      try {
        const res = await candidateService.getCandidateTimeline(id);
        if (res.data.data.success) {
          candidateTimelines.value =[]
          candidateTimelines.value = res.data.data.candidate;
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    onMounted(() => {
      getCandidateTimelines(props.candidateId);
    });
    return {
      candidateTimelines,
      getCandidateTimelines,
    };
  },
});
