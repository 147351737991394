
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  UnwrapRef,
} from "vue";
import auth from "../services/auth";
import { notification } from "ant-design-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import candidateService from "../services/candidate";
import commonService from "../services/common";
import {} from "@ant-design/icons-vue";

interface IreferenceRequest {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  profile_id: [];
  reference_required: string;
  notes: string;
  is_notify: boolean;
}

export default defineComponent({
  emits: ["getCandidatesList", "CloseCandidateModal"],
  props: ["From"],
  components: {},
  setup(props, { emit }) {
    const confirmLoading = ref<boolean>(false);
    const referenceRequestFormRef = ref();
    const openedFrom = ref();
    const allQprofiles = ref([]);
    const referenceRequestRules = validate.referenceRequestValidation;
    const referenceRequestModal: UnwrapRef<IreferenceRequest> = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      profile_id: [],
      reference_required: "",
      notes: "",
      is_notify: true
    });
    const limitNumber = (value: any) => {
      if (typeof value === "string") {
        // eslint-disable-next-line no-useless-escape
        return !isNaN(Number(value)) ? value.replace(new RegExp(/[^1-9\.]/g), ""): 0;
      } else if (typeof value === "number") {
        // eslint-disable-next-line no-useless-escape
        return !isNaN(value) ? String(value).replace(new RegExp(/[^1-9\.]/g), ""): 0;
      } else {
        return 0;
      }
    };
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const sendNewReferenceRequest = async (value: any) => {
      confirmLoading.value = true;
      try {
        const res = await candidateService.candidateReferenceRequest(value);
        if (res.data.data.success) {
          await notify("Success", res.data.data.message, "success");
          referenceRequestFormRef.value.resetFields();
          localStorage.available_credits = res.data.data.available_credits;
          if (props.From === "modal") {
            emit("CloseCandidateModal");
          } else {
            emit("getCandidatesList");
          }
        }
        confirmLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
          if (error.response.data.reference_required) {
            await notify(
              "Error",
              error.response.data.reference_required[0],
              "error"
            );
          } else {
            await notify("Error", error.response.data.message, "error");
          }
        }
        confirmLoading.value = false;
      }
    };
    const sendRequest = async () => {
      referenceRequestFormRef.value
        .validate()
        .then(() => {
          sendNewReferenceRequest(referenceRequestModal);
        })
        .catch((error: ValidateErrorEntity<IreferenceRequest>) => {
          console.log("error", error);
        });
    };
    const cancelCandidaterequest = async () => {
      emit("CloseCandidateModal", "CloseModal");
    };
    const filterOption = (input: string, option: any) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const getQuestionProfile = async () => {
      allQprofiles.value = [];
      try {
        const res = await candidateService.getAllQuestionProfiles();
        if (res.data.data.success) {
          allQprofiles.value = res.data.data.profiles;
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    // remote select user starts
    const showLogezyCandidates = ref<boolean>(false);
    const selectedCandidate = ref();
    const allCandidateLists = ref([]);
    const fetchUser = async () => {
      allCandidateLists.value = [];
      try {
        const res = await auth.logezycandidates();
        if (res.data.success) {
          allCandidateLists.value = res.data.candidates;
        }
      } catch (error: any) {
        if (error.response) {
          await notify("Error", error.response.data.message, "error");
        }
      }
    };
    const onSelecteCandidate = (val: any) => {
      const filteredCandidate = allCandidateLists.value.filter((itm: any) => {
        return itm.value === val;
      });
      if (filteredCandidate[0] !== undefined) {
        const selectedCandidate = filteredCandidate[0];
        referenceRequestModal.first_name = selectedCandidate.first_name;
        referenceRequestModal.last_name = selectedCandidate.last_name;
        referenceRequestModal.email = selectedCandidate.email;
        referenceRequestModal.phone = selectedCandidate.formatted_mobile_number;
      } else {
        referenceRequestModal.first_name = "";
        referenceRequestModal.last_name = "";
        referenceRequestModal.email = "";
        referenceRequestModal.phone = "";
      }
    };
    onMounted(() => {
      getQuestionProfile();
      openedFrom.value = props.From;
      allCandidateLists.value = [
        {
          id: 138,
          first_name: "Arathi",
          last_name: "Thomes",
          email: "arhithomas@gmail.com",
          formatted_mobile_number: "+91 96565 01002",
        },
        {
          id: 139,
          first_name: "Armol",
          last_name: "Thomes",
          email: "Armol@gmail.com",
          formatted_mobile_number: "+91 1111 21222",
        },
      ];
      const agency = JSON.parse(localStorage.getItem("profile"));
      if (agency != undefined && agency != "") {
        if (agency.is_from_logezy == 1) {
          fetchUser();
          showLogezyCandidates.value = true;
        }
      }
    });
    return {
      referenceRequestFormRef,
      referenceRequestModal,
      referenceRequestRules,
      limitNumber,
      confirmLoading,
      sendRequest,
      filterOption,
      notify,
      openedFrom,
      allQprofiles,
      cancelCandidaterequest,
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
      fetchUser,
      selectedCandidate,
      onSelecteCandidate,
      allCandidateLists,
      showLogezyCandidates,
      commonService
    };
  },
});
